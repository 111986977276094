export const isSingleBet = (selectedSystems, numberOfSelections) => {
  if (numberOfSelections > 1 || selectedSystems.length > 1) return false;

  return selectedSystems[0] === numberOfSelections;
};

export const isComboBet = (selectedSystems, numberOfSelections) => {
  if (numberOfSelections < 2 || selectedSystems.length > 1) return false;

  return selectedSystems[0] === numberOfSelections;
};

export const isSystemBet = (selectedSystems, numberOfSelections) => {
  if (numberOfSelections < 2) return false;

  if (selectedSystems.length > 1) return true;

  return selectedSystems[0] < numberOfSelections;
};

export const getTicketType = ({ selectedSystems = [], selections = [] } = {}) => {
  if (!selections.length || !selectedSystems.length) return null;

  const keys = {
    1: 'combo',
    2: 'system',
    3: 'single',
  };

  if (isComboBet(selectedSystems, selections.length)) return { id: 1, key: keys[1] };
  if (isSystemBet(selectedSystems, selections.length)) return { id: 2, key: keys[2] };
  if (isSingleBet(selectedSystems, selections.length)) return { id: 3, key: keys[3] };

  return null;
};
