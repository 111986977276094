import { useBreakpoints } from '@vueuse/core';

export default function useBreakpointsCreator({ reducer = 0 } = {}) {
  const breakpoints = useBreakpoints({
    xs: 768 - reducer,
    sm: 1023 - reducer,
    md: 1279 - reducer,
    lg: 1439 - reducer,
    xl: 1679 - reducer,
    xxl: 1919 - reducer,
  });

  const smallerThanLg = breakpoints.smaller('lg');
  const smallerThanOrEqualToMd = breakpoints.smallerOrEqual('md');
  const smallerOrEqualThanXl = breakpoints.smaller('xl');
  const betweenLgAndXL = breakpoints.between('lg', 'xl');
  const greaterOrEqualToXxl = breakpoints.greaterOrEqual('xxl');
  const betweenLgAndXxl = breakpoints.between('lg', 'xxl');
  const betweenMdAndLg = breakpoints.between('md', 'lg');
  const betweenSmAndMd = breakpoints.between('sm', 'md');
  const betweenXsAndSm = breakpoints.between('xs', 'sm');

  return {
    breakpoints,
    smallerThanLg,
    smallerOrEqualThanXl,
    smallerThanOrEqualToMd,
    betweenLgAndXL,
    greaterOrEqualToXxl,
    betweenLgAndXxl,
    betweenMdAndLg,
    betweenSmAndMd,
    betweenXsAndSm,
  };
}
