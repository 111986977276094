const MAX_CURRENT_GAME_SCORE = 40;

const statusMap = {
  1: 'NS',
  2: '1S',
  3: '1B',
  4: '2S',
  5: '2B',
  6: '3S',
  7: '3B',
  8: '4S',
  9: '4B',
  10: '5S',
  11: 'END',
};

const periodsMap = {
  1: '1S',
  2: '2S',
  3: '3S',
  4: '4S',
  5: '5S',
};

function mapTennisPeriods(periods = []) {
  if (!periods?.length) return [];

  return periods.reduce((score, { id, value: n }) => {
    score.push({ label: periodsMap[id], scores: [n.home, n.away] });
    return score;
  }, []);
}

function mapCurrentGameScore(gameEvents) {
  const gameScore = gameEvents.find((event) => event.id === 3)?.value ?? null;
  if (!gameScore) return null;

  // @ToDo: Add translation for **A** value.
  return Object.keys(gameScore).reduce((acc, n) => {
    acc[n] = gameScore[n] > MAX_CURRENT_GAME_SCORE ? 'A' : gameScore[n];
    return acc;
  }, {});
}

export function mapTennisEvent(event) {
  const currentGameScore = mapCurrentGameScore(event.events);
  const currentScoreInCurrentSet = event.events?.find((event) => event.id === 2)?.value ?? null;

  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: mapTennisPeriods(event.events.find((event) => event.id === 4)?.periods),
    currentScoreInSets: event.events?.find((event) => event.id === 1)?.value ?? null,
    currentScoreInCurrentSet,
    currentGameScore,
    compactModeScores: [currentGameScore, currentScoreInCurrentSet],
    server: event.events.find((event) => event.id === 5)?.value ?? null,
  };
}
