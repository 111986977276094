import mitt from 'mitt';
window.emitter = mitt();

import { createApp } from 'vue';
import { createPinia } from 'pinia';

import { vTooltip } from 'floating-vue';
import 'floating-vue/dist/style.css';

import { initSentry } from '@/utils/sentry.js';
import { VRateLimiter, VRateLimiterListener } from './utils/vRateLimiter.js';

import router from './router';
import App from './App.vue';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';

window.punterToken = null;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
window.dayjs = dayjs;

const app = createApp(App);

initSentry(app);

app.directive('tooltip', vTooltip);
app.directive('rate-limiter', VRateLimiter);
app.directive('rate-limiter-listener', VRateLimiterListener);

app.use(createPinia());
app.use(router);

app.mount('#app');
