const statusMap = {
  1: 'NS',
  2: '1G',
  3: '1B',
  4: '2G',
  5: '2B',
  6: '3G',
  7: '3B',
  8: '4G',
  9: '4B',
  10: '5G',
  11: 'END',
};

const scorePeriodsMap = {
  1: '1G',
  2: '2G',
  3: '3G',
  4: '4G',
  5: '5G',
};

function mapPeriods(periods = []) {
  if (!periods?.length) return [];

  return periods.reduce((score, { id, value: n }) => {
    score.push({ label: scorePeriodsMap[id], scores: [n.home, n.away] });
    return score;
  }, []);
}

export function mapSquashEvent(event) {
  const currentScoreInCurrentGame = event.events?.find((event) => event.id === 2)?.value ?? {};

  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: mapPeriods(event.events?.find((event) => event.id === 3)?.periods),
    currentScoreInSets: event.events?.find((event) => event.id === 1)?.value ?? {},
    currentScoreInCurrentSet: currentScoreInCurrentGame,
    server: event.events?.find((event) => event.id === 4)?.value ?? {},
    compactModeScores: [currentScoreInCurrentGame],
  };
}
