import { io } from 'socket.io-client';

import { useBetslipStore } from '@/stores/BetslipStore';

let socket;
export function establishBetslipDistributionSocketConnection(punter, forceConnection = false) {
  const betslipStore = useBetslipStore();

  const {
    setBetProcessing,
    displayBetRejectedReasons,
    handleBetslipAfterPlacedBet,
    displayMultipleRejectedBetsMessage,
    handleAlternativeStakes,
    updatePendingPlacedBets,
  } = betslipStore;

  if (socket?.connected) {
    if (!forceConnection) return;
    socket.disconnect();
    socket = null;
  }

  socket = io(import.meta.env.VITE_BETSLIP_DISTRIBUTION, {
    path: '/client/distribution/sio',
    query: {
      playerId: punter.id,
    },
    auth: {
      token: punterToken,
    },
    transports: ['websocket'],
  });

  socket.on('placementResult', (rawData) => {
    if (!betslipStore.betProcessing) return;

    setBetProcessing(false);

    const data = JSON.parse(rawData);
    const acceptedBets = [];
    const rejectedBets = [];
    let acceptedBetsCount = 0;
    let rejectedBetsCount = 0;

    updatePendingPlacedBets({ id: data.reqUuid, value: false });

    data.bets.forEach((bet) => {
      if (bet.status === 'PLACED') {
        acceptedBetsCount += 1;
        acceptedBets.push(bet);
      }

      if (bet.status === 'REJECTED') {
        rejectedBetsCount += 1;
        rejectedBets.push(bet);
      }
    });

    if (acceptedBetsCount) {
      handleBetslipAfterPlacedBet({
        acceptedBets,
        rejectedBetsCount,
      });

      emitter.emit('fetch-wallet');
    }

    if (!rejectedBetsCount) return;

    handleAlternativeStakes(data.bets, data.betslipId);

    if (rejectedBetsCount === 1) {
      const rejectedReasons = rejectedBets[0].reasons?.reduce((acc, { reason }) => {
        return acc.some((existingReason) => existingReason === reason) ? acc : [...acc, reason];
      }, []);
      displayBetRejectedReasons(rejectedReasons);
      return;
    }

    displayMultipleRejectedBetsMessage({
      showRejectedBetsCount: !!acceptedBetsCount,
      rejectedBetsCount,
    });
  });
}

export function disconnectFromBetslipDistributionSocketConnection() {
  if (socket?.id) {
    socket.disconnect();
    socket = null;
  }
}
