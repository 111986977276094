export const GA_CUSTOM_EVENTS = {
  bet_placed: 'bet_placed',
  delete_all_selections: 'delete_all_selections',
  predefined_stake_selection: 'predefined_stake_selection',
  rebet_selected: 'rebet_selected',
  remove_betslip_selection: 'remove_betslip_selection',
  select_betslip_selection: 'select_betslip_selection',
  deselect_betslip_selection: 'deselect_betslip_selection',
  betslip_settings_open: 'betslip_settings_open',
  undo_betslip_clearance: 'undo_betslip_clearance',
  cashout: 'cashout',
  click_on_bet_assist: 'click_on_bet_assist',
  show_more_clicked: 'show_more_clicked',
  show_less_clicked: 'show_less_clicked',
  click_on_sport: 'click_on_sport',
  click_on_category: 'click_on_category',
  click_on_tournament: 'click_on_tournament',
  statistics_clicked: 'statistics_clicked',
  eventview_open: 'eventview_open',
  additional_options_menu_opened: 'additional_options_menu_opened',
  favorite_events_selected: 'favorite_events_selected',
  favorite_event_selected: 'favorite_event_selected',
  favorite_tournament_selected: 'favorite_tournament_selected',
  favorite_event_market_selected: 'favorite_event_market_selected',
  layout_change: 'layout_change',
  live_match_tracker_selected: 'live_match_tracker_selected',
  market_change_view: 'market_change_view',
  market_group_selected: 'market_group_selected',
  live_open: 'live_open',
  sport_open: 'sport_open',
  outcome_selected: 'outcome_selected',
  promoted_offer_selected: 'promoted_offer_selected',
  promoted_offer_catalog_selected: 'promoted_offer_catalog_selected',
  search_request_sent: 'search_request_sent',
  search_option_selected: 'search_option_selected',
  stream_events_selected: 'stream_events_selected',
  stream_selected: 'stream_selected',
  time_filter_selected: 'time_filter_selected',
  view_change: 'view_change',
  boosted_betslips_add_to_betslip: 'boosted_betslips_add_to_betslip',
  boosted_betslips_view_betslip: 'boosted_betslips_view_betslip',
  boosted_betslips_full_view_close: 'boosted_betslips_full_view_close',
  boosted_betslips_full_view_open: 'boosted_betslips_full_view_open',
};
