import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { orderBy } from 'lodash-es';

import { useBetslipStore } from '@/stores/BetslipStore';
import { useStore } from '@/stores/store';

import useSupplemental from '@/composables/supplemental/useSupplemental.js';
import { getBetBuilderCalculations } from '@/api/custom-bet';

const collections = {
  offer: {
    markets: 'markets',
    outcomes: 'outcomes',
    events: 'events',
  },
  promotedEvents: {
    markets: 'markets',
    outcomes: 'outcomes',
    events: 'promotedEvents',
  },
  eventview: {
    markets: 'eventviewMarkets',
    outcomes: 'eventviewOutcomes',
    events: 'eventviewEvent',
  },
};

export default function useBetslip() {
  const betslipStore = useBetslipStore();
  const store = useStore();

  const {
    getSupplemental,
    getMarketSupplementalName,
    getMarketOutcomeSupplementalName,
    getCompetitorSupplementalName,
  } = useSupplemental('betslip');

  function retrieveEventMeta({ eventId, eventMarketId, eventMarketOutcomeId, origin }) {
    const collection = collections[origin];

    const event = store?.[collection.events]?.size
      ? store[collection.events]?.get(+eventId)
      : store[collections[origin].events];

    const eventMarket = event.markets.get(+eventMarketId);
    const eventMarketOutcome = eventMarket.outcomes.get(+eventMarketOutcomeId);

    const metaMarket = store[collection.markets].get(eventMarket.marketId);
    const metaOutcome = store[collection.outcomes].get(eventMarketOutcome.outcomeId);

    return {
      event,
      eventMarket,
      eventMarketOutcome,
      metaMarket,
      metaOutcome,
    };
  }

  async function handleBetBuilderOutcome(
    selectionIdx,
    selectionMarketIdx,
    selectionMarket,
    eventId,
  ) {
    const [marketId, outcomeId] = selectionMarketIdx.split('/');

    if (betslipStore.selectionsData[selectionIdx].markets.get(selectionMarketIdx)) {
      betslipStore.removeBetBuilderBet(selectionIdx, outcomeId, marketId);
    } else {
      betslipStore.selectionsData[selectionIdx].markets.set(selectionMarketIdx, selectionMarket);
    }

    const selections = store.getEventSelectionsForCalculation(eventId, selectionIdx);

    betslipStore.setBetslipLoading(true);
    try {
      if (selections.length) {
        const response = await getBetBuilderCalculations(eventId, selections);
        store.sendOfferWorkerMessage('update-event-bet-builder-selections', {
          eventId: eventId,
          selections: response?.selections ?? [],
        });
        betslipStore.selectionsData[selectionIdx].odds = response?.odds * 10000;
      } else {
        store.resetBetBuilderSelections(eventId);
      }
    } catch (error) {
      console.log(error);
    }
    betslipStore.setBetslipLoading(false);
  }

  function validateBetAdd(selectionIndex, type) {
    let message = '';

    if (
      type === 'bet_builder' &&
      betslipStore.selectionsList.some((selection) => !selection.includes(selectionIndex))
    ) {
      if (betslipStore.selectionsList.some((selection) => selection.includes('bet_builder'))) {
        message = 'bet_builder_error_two_bets';
      } else {
        message = 'bet_builder_error_with_regular_bet';
      }
    } else if (
      type !== 'bet_builder' &&
      betslipStore.selectionsList.some((selection) => selection.includes('bet_builder'))
    ) {
      message = 'bet_builder_error_with_bet_builder_bet';
    }

    if (message)
      store.addNotification({
        timeout: 2000,
        text: store.getTranslation(message),
        type: 'error',
      });

    return !message;
  }

  async function selectOutcome(rawSelectionIndex, origin, entryPoint) {
    if (betslipStore.betProcessing) return;

    const [eventId, eventMarketId, eventMarketOutcomeId, type = 'regular'] =
      rawSelectionIndex.split('/');

    const constructIndex = () => {
      if (type === 'regular') return `${eventId}/${eventMarketId}/${eventMarketOutcomeId}`;
      return `${eventId}/${type}`;
    };

    const selectionIndex = constructIndex();

    const valid = validateBetAdd(selectionIndex, type);
    if (!valid) return;

    if (
      type === 'bet_builder' &&
      betslipStore.selectionsData?.[selectionIndex]?.markets?.size === 10
    ) {
      store.addNotification({
        timeout: 2000,
        text: store.getTranslation('restriction_max_bet_builder_bets'),
        type: 'error',
      });
      return;
    }

    const foundSelectionId = betslipStore.selectionsList.find((selection) => {
      if (betslipStore.selectedTicketType === 'single' && store.offerView !== 'indonesian')
        return selection === selectionIndex;
      return selection.includes(eventId);
    });

    const { event, eventMarket, eventMarketOutcome, metaMarket, metaOutcome } = retrieveEventMeta({
      eventId,
      eventMarketId,
      eventMarketOutcomeId,
      origin,
    });

    const selectionMarket = {
      active: true,
      marketId: eventMarket.id,
      marketName:
        getSupplemental(eventMarket) ??
        getMarketSupplementalName(
          {
            ...eventMarket,
            supplementalNames: metaMarket?.supplementalNames ?? {},
            eventMarketName: metaMarket.eventMarketName,
            name: metaMarket.name,
          },
          event,
        ),
      metaMarketId: eventMarket.marketId,
      outcomeId: eventMarketOutcome.id,
      metaOutcomeId: eventMarketOutcome.outcomeId,
      outcomeName:
        getSupplemental(eventMarketOutcome) ??
        getMarketOutcomeSupplementalName(
          {
            ...eventMarketOutcome,
            supplementalNames: metaOutcome?.supplementalNames ?? {},
            eventMarketOutcomeName: metaOutcome.eventMarketOutcomeName,
            name: metaOutcome.name,
          },
          event,
          eventMarket,
        ),
      odds: eventMarketOutcome.odds,
    };

    const isSingleTicketTypeWithIndonesianOfferView =
      betslipStore.selectedTicketType === 'single' && store.offerView === 'indonesian';

    if (foundSelectionId) {
      const selection = betslipStore.selectionsData[foundSelectionId];

      if (foundSelectionId === selectionIndex) {
        if (selection.type === 'regular') {
          betslipStore.removeSelection(foundSelectionId);
          return;
        }

        if (selection.markets.has(`${eventMarketId}/${eventMarketOutcomeId}`)) {
          handleBetBuilderOutcome(
            selectionIndex,
            `${eventMarketId}/${eventMarketOutcomeId}`,
            selectionMarket,
            event.id,
          );

          return;
        }
      } else if (
        (betslipStore.selectedTicketType !== 'single' && type !== 'bet_builder') ||
        isSingleTicketTypeWithIndonesianOfferView
      )
        betslipStore.removeSelection(foundSelectionId);
    }

    if (!betslipStore.selectionsData[selectionIndex]) {
      if (isSingleTicketTypeWithIndonesianOfferView && betslipStore.selectionsList.length) {
        betslipStore.removeSelection(betslipStore.selectionsList[0]);
      }

      betslipStore.addSelection(selectionIndex, {
        active: true,
        id: selectionIndex,
        competitors: [2, 3].includes(event?.competitorType)
          ? [getSupplemental(event)]
          : orderBy(event.competitors, 'ordinal').map((competitor) =>
              getCompetitorSupplementalName(competitor),
            ),
        type,
        eventId: event.id,
        eventCompetitors: [],
        categoryId: event.categoryId,
        tournamentId: event.tournamentId,
        sportId: event.sportId,
        startsAt: event.startsAt,
        game: event.playStatus === 2 ? 'LIVE' : 'PREMATCH',
        playStatus: event.playStatus,
        metadata: event?.metadata ?? null,
        detailedPlayStatus: event?.detailedPlayStatus ?? null,
        limits: {},
        previousOdds: eventMarketOutcome.previousOdds,
        markets:
          type !== 'bet_builder'
            ? new Map([[`${eventMarketId}/${eventMarketOutcomeId}`, selectionMarket]])
            : new Map(),
        ...(type === 'regular' && { odds: eventMarketOutcome.odds }),
      });

      if (type === 'bet_builder') {
        handleBetBuilderOutcome(
          selectionIndex,
          `${eventMarketId}/${eventMarketOutcomeId}`,
          selectionMarket,
          event.id,
        );
      }
    } else
      switch (type) {
        case 'bet_builder':
          handleBetBuilderOutcome(
            selectionIndex,
            `${eventMarketId}/${eventMarketOutcomeId}`,
            selectionMarket,
            event.id,
          );
          break;
      }

    calculatePayments();
    window.pushEventToGoogleTagManager('outcome_selected', {
      entry_point: entryPoint,
    });
  }

  function isOutcomeSelected(selectionIdx) {
    const [eventId, eventMarketId, eventMarketOutcomeId, type] =
      typeof selectionIdx === 'string' ? selectionIdx.split('/') : '';

    if (betslipStore.selectionsData[`${eventId}/${type}`])
      return betslipStore.selectionsData[`${eventId}/${type}`].markets.has(
        `${eventMarketId}/${eventMarketOutcomeId}`,
      );

    return !!betslipStore.selectionsData[selectionIdx];
  }

  function calculatePaymentPerCombination() {
    const betslipStake = +betslipStore.stake;
    const stakeDividedByNumberOfCombinations = betslipStake / betslipStore.totalSystemCombinations;

    let sumOfPaymentsPerCombination = 0;
    betslipStore.systems = Object.values(betslipStore.systems).reduce((acc, n) => {
      const paymentPerCombination = parseFloat(
        stakeDividedByNumberOfCombinations * n.combinations,
      ).toFixed(2);
      acc[n.id] = {
        ...n,
        stake: paymentPerCombination,
      };
      sumOfPaymentsPerCombination += +paymentPerCombination;

      return acc;
    }, {});

    const systemKeys = Object.keys(betslipStore.systems);

    if (!systemKeys.length) return;

    if (sumOfPaymentsPerCombination < betslipStake) {
      const firstSystemId = systemKeys[0];
      const system = betslipStore.systems[firstSystemId];
      const stakeLeftover = +parseFloat(betslipStake - sumOfPaymentsPerCombination).toFixed(2);
      betslipStore.systems[firstSystemId].stake = parseFloat(+system.stake + stakeLeftover).toFixed(
        2,
      );
    }

    if (sumOfPaymentsPerCombination > betslipStake) {
      const lastSystemId = systemKeys[systemKeys.length - 1];
      const system = betslipStore.systems[lastSystemId];

      const stakeLeftover = +parseFloat(sumOfPaymentsPerCombination - betslipStake).toFixed(2);
      betslipStore.systems[lastSystemId].stake = parseFloat(+system.stake - stakeLeftover).toFixed(
        2,
      );
    }
  }

  function calculatePaymentPerSelection() {
    const stakeDividedByNumberOfSelections = parseFloat(
      +betslipStore.stake / betslipStore.activeSelectionsCount,
    ).toFixed(2);

    for (const selectionId of betslipStore.selectionsList)
      betslipStore.selectionsData[selectionId].payment = stakeDividedByNumberOfSelections;

    const sumOfPaymentsPerSelection =
      +stakeDividedByNumberOfSelections * betslipStore.activeSelectionsCount;

    if (sumOfPaymentsPerSelection < +betslipStore.stake) {
      const selectionPayment = +betslipStore.selectionsData[betslipStore.selectionsList[0]].payment;
      const stakeLeftover = +parseFloat(+betslipStore.stake - sumOfPaymentsPerSelection).toFixed(2);

      const firstActiveSelection = betslipStore.selectionsList.find(
        (id) => betslipStore.selectionsData[id].active,
      );
      betslipStore.selectionsData[firstActiveSelection].payment = parseFloat(
        selectionPayment + stakeLeftover,
      ).toFixed(2);
    }

    if (sumOfPaymentsPerSelection > +betslipStore.stake) {
      const selectionPayment = +betslipStore.selectionsData[betslipStore.selectionsList[0]].payment;
      const stakeLeftover = +parseFloat(sumOfPaymentsPerSelection - +betslipStore.stake).toFixed(2);

      betslipStore.selectionsData[
        betslipStore.selectionsList[betslipStore.selectionsList.length - 1]
      ].payment = parseFloat(selectionPayment - stakeLeftover).toFixed(2);
    }
  }

  function calculatePayments() {
    if (betslipStore.selectedTicketType === 'system') calculatePaymentPerCombination();
    if (betslipStore.selectedTicketType === 'single') calculatePaymentPerSelection();
  }

  const { punterWallet } = storeToRefs(useStore());
  const { stake } = storeToRefs(useBetslipStore());

  const punterBalanceInsufficient = computed(
    () =>
      punterWallet.value.enabled &&
      parseFloat(punterWallet.value.activeCurrencyBalance) < parseFloat(stake.value),
  );

  return {
    selectOutcome,
    isOutcomeSelected,
    calculatePayments,
    punterBalanceInsufficient,
  };
}
