const statusMap = {
  1: 'NS',
  2: '1H',
  3: 'HT',
  4: '2H',
  5: 'END',
  6: 'A. OT',
  7: '1H.OT',
  8: 'HT.OT',
  9: '2P.OT',
  10: 'END.A.OT',
  11: 'A.GR',
  12: 'GR',
  13: 'IP',
  14: 'END.A.GR',
};

const scorePeriodsMap = {
  1: '1H',
  2: '2H',
  3: 'OT',
  4: '1H.OT',
  5: '2H.OT',
  6: 'GR',
};

function mapPeriods(periods = []) {
  if (!periods?.length) return [];

  return periods.reduce((score, { id, value: n }) => {
    score.push({ label: scorePeriodsMap[id], scores: [n.home, n.away] });
    return score;
  }, []);
}

export function mapKabaddiEvent(event) {
  const currentGameScore = event.events.find((event) => event.id === 1)?.value ?? null;

  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: mapPeriods(event.events.find((event) => event.id === 3)?.periods),
    currentGameScore,
    compactModeScores: [currentGameScore],
  };
}
