import { GA_CUSTOM_EVENTS } from './googleAnalyticsCustomEvents';

function gtag() {
  if (!window.dataLayer) return;
  window.dataLayer.push(arguments);
}

const initGoogleTagManager = (gtmId = 'G-868SBRFFP7') => {
  const gtmScriptElement = document.createElement('script');
  gtmScriptElement.async = true;
  gtmScriptElement.type = 'text/javascript';
  gtmScriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
  document.body.appendChild(gtmScriptElement);

  window.dataLayer = window.dataLayer || [];

  gtag('js', new Date());
  gtag('config', gtmId, {
    cookie_flags: 'samesite=none;secure',
  });
};

window.pushEventToGoogleTagManager = function (eventName, eventData) {
  if (eventData) {
    gtag('event', GA_CUSTOM_EVENTS[eventName] ?? eventName, eventData);
  } else {
    gtag('event', GA_CUSTOM_EVENTS[eventName] ?? eventName);
  }
};

export { initGoogleTagManager };
