const statusMap = {
  1: 'NS',
  2: '1Q',
  3: '1B',
  4: '2Q',
  5: '2B',
  6: '3Q',
  7: '3B',
  8: '4Q',
  9: 'END',
  10: 'A.OT',
  11: 'OT',
  12: 'END.A.OT',
};

const scorePeriodsMap = {
  1: '1Q',
  2: '2Q',
  3: '3Q',
  4: '4Q',
  5: 'OT',
};

function mapPeriods(periods = []) {
  if (!periods?.length) return [];

  return periods.reduce((score, { id, value: n }) => {
    score.push({ label: scorePeriodsMap[id], scores: [n.home, n.away] });
    return score;
  }, []);
}

export function mapFieldHockeyEvent(event) {
  const currentGameScore = event.events.find((event) => event.id === 1)?.value ?? null;

  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: mapPeriods(event.events.find((event) => event.id === 3)?.periods),
    currentGameScore,
    compactModeScores: [currentGameScore],
    eventTime: event.events.find((event) => event.id === 4)?.value?.value || 0,
  };
}
