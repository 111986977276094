/**
 * @description returns string of query parameters that can be attached to url
 * @param {{}} obj
 * @returns {string} e.g. ?key1:value1&key2=value2
 */
export function constructQueryFromObject(obj) {
  const keyValuePairs = Object.entries(obj);

  if (!keyValuePairs.length) return '';

  const queryParams = keyValuePairs
    .map(([key, value], index) => {
      if (typeof value === 'object' && !Array.isArray(value)) {
        return Object.entries(value ?? {})
          .map(([subKey, subValue]) => `&clientIds=${subKey}:${subValue}`)
          .join('');
      }
      if (key === 'supplementalNameTypeIds') {
        return (
          value?.reduce((acc, val) => {
            return `${acc}&${key}=${val}`;
          }, '') ?? ''
        );
      } else {
        return `${index === 0 ? '' : '&'}${key}=${value}`;
      }
    })
    .join('');

  return `?${queryParams}`;
}
