import * as fzstd from 'fzstd';

import { handleWSMessage } from './messageHandler';
import { constructClientIds } from '../../../utils/helpers';
import { constructQueryFromObject } from '../../../api/api-helpers';
import { checkOnline } from '@/api/online-checker';

const { VITE_WS_URL, VITE_WS_COMPRESSION } = import.meta.env;

const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));

const WS_ENCODING = +VITE_WS_COMPRESSION ? 'zstd' : 'plaintext';
const MESSAGES_DEBUG_MODE = queryParams?.debug?.toLowerCase() === 'true';
const EVENT_DEBUG = queryParams?.debugEvent;
const DEBUG_TYPE = queryParams?.debugType?.toLowerCase();
const OFFER_SOCKET_DEBUG_MODE = queryParams?.offerSocketDebug?.toLowerCase() === 'true';
const DEBUG_MODE = OFFER_SOCKET_DEBUG_MODE;

let webSocket = null;
let messagesQueue = [];
let connectedGame = null;
let activeConnectionTimestamp = null;
let socketInitializationTimestamp = null;

let subscribedEvents = new Map();
let metaSubscribedEvents = new Map();

const reInitTerminationInfo = {
  code: 4000,
  reason: 'Closed internally upon Socket reinitialization.',
};
const pongTerminationInfo = {
  code: 4001,
  reason: 'Closed internally upon Pong timeout.',
};

let checkAliveTimeout = null;
let pongTimeout = null;
const checkAliveTimeoutDuration = 8000;
const pongTimeoutDuration = 5000;

async function isOnline() {
  // Avoid http request if Navigator itself concludes it is offline.
  if (!navigator.onLine) return false;
  return await checkOnline();
}

export function sendOfferDistributionWSMessage(message) {
  if (webSocket && webSocket?.readyState === webSocket?.OPEN)
    webSocket.send(JSON.stringify(message));
  else messagesQueue.push(message);
}

function reconnectSocketOnCloseEvent(gameId, params) {
  const reconnectTimeout = setTimeout(async () => {
    clearTimeout(reconnectTimeout);

    if (await isOnline()) {
      // Since we're skipping terminate() call, annul connectedGame.
      connectedGame = null;
      initializeOfferDistributionWSConnection(gameId, params, false);
    }
  }, 2000);
}

function startPongTimeout(gameId, params) {
  pongTimeout = setTimeout(async () => {
    if (await isOnline()) {
      if (DEBUG_MODE)
        console.log('[AIO Offer WS] Pong Timeout done, is online, trying to reconnect.');

      initializeOfferDistributionWSConnection(gameId, params, true, pongTerminationInfo);
    } else {
      if (DEBUG_MODE)
        console.log('[AIO Offer WS] Pong Timeout done, not online, restarting CheckAlive.');
      startCheckAlive(gameId, params, false);
    }
  }, pongTimeoutDuration);
}

function startCheckAlive(gameId, params, shouldSendPing = true) {
  clearTimeout(checkAliveTimeout);
  clearTimeout(pongTimeout);

  checkAliveTimeout = setTimeout(() => {
    if (DEBUG_MODE) {
      const pingPongStatusMsg = shouldSendPing ? 'sending ping to server' : 'starting Pong Timeout';
      console.log(`[AIO Offer WS] CheckAlive Timeout done, ${pingPongStatusMsg}.`);
    }

    if (shouldSendPing) sendOfferDistributionWSMessage({ type: 'ping' });

    startPongTimeout(gameId, params);
  }, checkAliveTimeoutDuration);
}

function terminateOfferDistributionWSConnection(info) {
  if (!webSocket) return;

  webSocket.close(info.code, info.reason);
  webSocket = null;
  connectedGame = null;
}

function connectOfferDistributionWS(gameId, params) {
  connectedGame = gameId;

  const query = constructQueryFromObject({
    encoding: WS_ENCODING,
    clientIds: constructClientIds(),
    ...params,
  });
  webSocket = new WebSocket(
    `${VITE_WS_URL}/tenants/${window.tenantUuid}/games/${gameId}/languages/${window.languageCode}${query}`,
  );

  webSocket.binaryType = 'arraybuffer';

  webSocket.onopen = () => {
    if (DEBUG_MODE) console.log('[AIO Offer WS] Connection is established.');

    activeConnectionTimestamp = Date.now();
    webSocket.connectionCreatedAt = activeConnectionTimestamp;

    // Start CheckAlive timers on connection open.
    startCheckAlive(gameId, params);

    while (messagesQueue.length > 0) {
      const message = messagesQueue.shift();
      sendOfferDistributionWSMessage(message);
    }

    if (subscribedEvents.size) {
      sendOfferDistributionWSMessage({
        type: 'subscribe_to_offer_changes',
        events: [...subscribedEvents].map(([, event]) => event),
      });
    }

    if (metaSubscribedEvents.size) {
      sendOfferDistributionWSMessage({
        type: 'subscribe_to_live_metadata_changes',
        events: [...metaSubscribedEvents].map(([, event]) => event),
      });
    }
  };

  const textDecoder = new TextDecoder();
  webSocket.onmessage = (message) => {
    // Restart CheckAlive timers on each new message.
    startCheckAlive(gameId, params);

    const data = JSON.parse(
      WS_ENCODING === 'zstd'
        ? textDecoder.decode(fzstd.decompress(new Uint8Array(message.data)))
        : textDecoder.decode(message.data),
    );

    if (MESSAGES_DEBUG_MODE || DEBUG_MODE) {
      console.log(DEBUG_TYPE === 'json' ? JSON.stringify(data) : data);
    }

    // Abort here, there is no need for further handling of server 'pong' message.
    if (data.type === 'pong') return;

    if (EVENT_DEBUG && data.events) {
      const foundEvent = data.events.find(({ id }) => id === +EVENT_DEBUG);
      if (foundEvent) {
        console.log(data.type, DEBUG_TYPE === 'json' ? JSON.stringify(foundEvent) : data);
      }
    }

    handleWSMessage(data);
  };

  webSocket.onerror = (event) => {
    console.error("[AIO Offer WS] We've caught Web Socket error.", event);
  };

  webSocket.onclose = (event) => {
    if (DEBUG_MODE) console.error('[AIO Offer WS] Connection has been closed.', event);

    // If onclose event fired for our latest established connection, annul timestamp.
    if (event.currentTarget?.connectionCreatedAt === activeConnectionTimestamp)
      activeConnectionTimestamp = null;

    // If onclose event fired for socket which doesn't contain our creation timestamp
    // or socket timestamp is earlier than timestamp of our currently open socket connection,
    // skip reconnect action.
    if (
      !event.currentTarget?.connectionCreatedAt ||
      (activeConnectionTimestamp &&
        event.currentTarget?.connectionCreatedAt < activeConnectionTimestamp)
    )
      return;

    // If socket was closed because we internally terminated the connection, skip reconnect action.
    if (event.code === reInitTerminationInfo.code || event.code === pongTerminationInfo.code)
      return;

    // If connection closed from outside, reconnect socket in 2 seconds.
    reconnectSocketOnCloseEvent(gameId, params);
  };
}

export function initializeOfferDistributionWSConnection(
  gameId,
  params,
  mayTerminate = true,
  terminationInfo,
) {
  const gameChanged = connectedGame && gameId !== connectedGame;

  // If game is connected and initialize() is called for a different game, remap events.
  if (gameChanged) {
    subscribedEvents = new Map();
    metaSubscribedEvents = new Map();
  }

  // If socket is being initialized for a 2nd or any subsequent time,
  // terminate connection (which will also annul connectedGame).
  if (socketInitializationTimestamp && mayTerminate) {
    terminateOfferDistributionWSConnection(terminationInfo || reInitTerminationInfo);
  }

  socketInitializationTimestamp = Date.now();

  connectOfferDistributionWS(gameId, params);
}

export function subscribeEventsOnOfferChanges(events) {
  sendOfferDistributionWSMessage({
    type: 'subscribe_to_offer_changes',
    events,
  });

  events?.forEach((event) => {
    subscribedEvents.set(event.id, event);
  });
}

export function subscribeEventsOnMetadataChanges(events) {
  sendOfferDistributionWSMessage({
    type: 'subscribe_to_live_metadata_changes',
    events,
  });

  events?.forEach((event) => {
    metaSubscribedEvents.set(event, event);
  });
}

/**
 * Subscribes offer events to distribution socket.
 * @function unsubscribeEventsFromOfferDistributionSocket
 * @returns {void}
 */
export function unsubscribeEventsFromOfferDistributionSocket(events) {
  sendOfferDistributionWSMessage({
    type: 'unsubscribe_from_offer_changes',
    events,
  });

  sendOfferDistributionWSMessage({
    type: 'unsubscribe_from_live_metadata_changes',
    events,
  });

  events?.forEach((event) => {
    subscribedEvents.delete(event);
    metaSubscribedEvents.delete(event);
  });
}
