export const VRateLimiter = {
  mounted(el, binding) {
    let clickTimes = [];
    const maxClicks = binding.value?.maxClicks || 3;
    const timeFrame = binding.value?.timeFrame || 2000;

    const handleClick = (event, child) => {
      const now = Date.now();
      clickTimes = clickTimes.filter((time) => now - time < timeFrame);

      if (clickTimes.length < maxClicks) {
        clickTimes.push(now);
        child.dispatchEvent(new CustomEvent('propagate-rate-limited-event'));
      } else {
        event.stopImmediatePropagation();
        event.preventDefault();
      }
    };

    for (const child of el.children) {
      child.addEventListener('click', (e) => handleClick(e, child));
      child._handleClick = handleClick;
    }
  },
  unmounted(el) {
    for (const child of el.children) {
      child.removeEventListener('click', child._handleClick);
    }
  },
};

export const VRateLimiterListener = {
  mounted(el, binding) {
    const handler = binding.value?.handler ?? (() => {});
    const val = binding.value?.val ?? null;

    el.addEventListener('propagate-rate-limited-event', () => handler(val));
    el._handler = handler;
  },
  unmounted(el) {
    el.removeEventListener('propagate-rate-limited-event', el._handler);
  },
};
