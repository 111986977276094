import { v4 as uuidv4 } from 'uuid';

const formatComboBets = (rawSelections, stake, cachedIds) => {
  const selections = Object.values(rawSelections ?? {}).filter(
    (selection) =>
      selection.active &&
      selection.odds &&
      [...selection.markets].filter(([, m]) => m.active).length,
  );

  if (!selections.length) return [];

  return [
    {
      type: 'OPEN',
      selections: selections.reduce((acc, n) => {
        const selectionCached = !![...n.markets].filter(
          ([key]) => cachedIds?.[n?.id]?.markets?.[key],
        ).length;

        const selection = selectionCached
          ? cachedIds[n.id]
          : {
              ...n,
              categoryId: `${n.sportId}/${n.categoryId}/${n.tournamentId}`,
            };

        acc.push({
          type: 'OPEN',
          id: selection.id,
          event: selectionCached
            ? selection.event
            : { id: selection.eventId, startTime: selection.startsAt },
          gameContext: {},
          odds: n.odds,
          banker: false,
          game: selection.game,
          competitors: selectionCached ? selection.competitors : selection.eventCompetitors,
          categoryId: selection.categoryId,
          markets: selectionCached
            ? (cachedIds?.[n?.id]?.markets ?? {})
            : [...n.markets].reduce((acc, [, market]) => {
                acc[`${market.marketId}/${market.outcomeId}`] = {
                  eventMarketId: market.marketId,
                  marketId: market.metaMarketId,
                  eventMarketOutcomeId: market.outcomeId,
                  outcomeId: market.metaOutcomeId,
                  cached: false,
                };

                return acc;
              }, {}),
          cached: selectionCached,
          synSelectionId: n.id,
        });

        return acc;
      }, []),
      selectedSystems: [selections.length],
      gameContext: {},
      stake: {
        type: 'TOTAL',
        amount: stake || 1,
      },
    },
  ];
};

const formatSingleBets = (rawSelections, stake, cachedIds, isBettingWithDesiredProfit) => {
  const selections = Object.values(rawSelections ?? {}).filter(
    (selection) =>
      selection.active &&
      selection.odds &&
      [...selection.markets].filter(([, m]) => m.active).length,
  );
  const splitStake = parseFloat((parseFloat(stake) / selections.length || 1).toFixed(2));

  return selections.map((n) => {
    const selection = cachedIds?.[n.id] ?? {
      ...n,
      categoryId: `${n.sportId}/${n.categoryId}/${n.tournamentId}`,
    };

    const selectionCached = !![...n.markets].filter(([key]) => cachedIds?.[n?.id]?.markets?.[key])
      .length;

    return {
      type: 'OPEN',
      selections: [
        {
          type: 'OPEN',
          id: selection.id,
          event: selectionCached
            ? selection.event
            : { id: selection.eventId, startTime: selection.startsAt },
          competitors: selectionCached ? selection.competitors : selection.eventCompetitors,
          gameContext: {},
          odds: selection.odds,
          banker: false,
          game: selection.game,
          categoryId: selection.categoryId,
          markets: selectionCached
            ? (cachedIds?.[n?.id]?.markets ?? {})
            : [...n.markets].reduce((acc, [, market]) => {
                acc[`${market.marketId}/${market.outcomeId}`] = {
                  eventMarketId: market.marketId,
                  marketId: market.metaMarketId,
                  eventMarketOutcomeId: market.outcomeId,
                  outcomeId: market.metaOutcomeId,
                  cached: false,
                };

                return acc;
              }, {}),
          cached: selectionCached,
        },
      ],
      selectedSystems: [1],
      gameContext: {},
      stake: {
        type: 'TOTAL',
        amount: isBettingWithDesiredProfit ? stake : n?.payment || splitStake || 1,
      },
    };
  });
};

const formatSystemBets = (selections, stake, cachedIds, systems) => {
  const selectionList = Object.values(selections ?? {}).filter(
    (selection) => selection.active && selection.odds,
  );
  const systemsList = Object.values(systems ?? {}).filter((system) => system.selected) || [];

  const stakePerSystem = systemsList.filter((system) => system.stake);
  if (!systemsList.length) return;

  const numOfBankers = selectionList.filter((selection) => selection.banker).length;

  return systemsList.map((system) => {
    return {
      type: 'OPEN',
      selections: selectionList.map((n) => {
        const selection = cachedIds[n.id] ?? {
          ...n,
          categoryId: `${n.sportId}/${n.categoryId}/${n.tournamentId}`,
        };
        const selectionCached = !![...n.markets].filter(
          ([key]) => cachedIds?.[n?.id]?.markets?.[key],
        ).length;

        return {
          type: 'OPEN',
          id: selection.id,
          event: selectionCached
            ? selection.event
            : { id: selection.eventId, startTime: selection.startsAt },
          gameContext: {},
          odds: selection.odds,
          banker: n?.banker || false,
          game: selection.game,
          categoryId: selection.categoryId,
          competitors: selectionCached ? selection.competitors : selection.eventCompetitors,
          markets: selectionCached
            ? (cachedIds?.[n?.id]?.markets ?? {})
            : [...n.markets].reduce((acc, [, market]) => {
                acc[`${market.marketId}/${market.outcomeId}`] = {
                  eventMarketId: market.marketId,
                  marketId: market.metaMarketId,
                  eventMarketOutcomeId: market.outcomeId,
                  outcomeId: market.metaOutcomeId,
                  cached: false,
                };

                return acc;
              }, {}),
          cached: selectionCached,
        };
      }),
      selectedSystems: [system.id + numOfBankers],
      gameContext: {},
      stake: {
        type: 'TOTAL',
        amount: (stakePerSystem.length <= 1 ? stake : parseFloat(system.stake)) || 1,
      },
    };
  });
};

export function formatBerryBetPlacement(
  { selections, stake, cachedIds, selectedTicketType, systems },
  isBettingWithDesiredProfit,
) {
  const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));

  const betslip = {
    betslip: {
      createdAt: new Date().toISOString(),
      context: {
        tenantId: window.tenantUuid,
        currency: window.currency,
        gameId: 'SportsAIO',
        punterIdentityType: 'ONLINE_PLAYER_WEB',
      },
    },
    meta: {
      tenantId: window.tenantUuid,
      punterId: window?.punter?.id,
      requestUuid: uuidv4(),

      // @ToDo: Remove in production
      externalId: queryParams?.externalId,
    },
  };

  switch (selectedTicketType) {
    case 'single':
      betslip.betslip.bets = formatSingleBets(
        selections,
        stake,
        cachedIds,
        isBettingWithDesiredProfit,
      );
      break;
    case 'system':
      betslip.betslip.bets = formatSystemBets(selections, stake, cachedIds, systems);
      break;
    case 'combo':
    default:
      betslip.betslip.bets = formatComboBets(selections, stake, cachedIds);
      break;
  }

  return betslip;
}
