const statusMap = {
  1: 'NS',
  2: 'IP',
  3: 'BREAK',
  4: 'END',
};

export function mapSnookerEvent(event) {
  const currentScoreInCurrentSet = event.events?.find((event) => event.id === 2)?.value ?? {};
  const currentScoreInSets = event.events.find((event) => event.id === 1)?.value ?? null;

  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: [
      {
        label: 'CF',
        scores: [currentScoreInCurrentSet.home, currentScoreInCurrentSet.away],
      },
    ],
    currentScoreInCurrentSet,
    currentScoreInSets,
    currentGameScore: currentScoreInSets,
    hideCurrentGameScore: true,
    compactModeScores: [currentScoreInCurrentSet],
  };
}
