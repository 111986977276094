import { isMobile } from '@/services/helpers';

const statusMap = {
  1: 'NS',
  2: '1S',
  3: '1B',
  4: '2S',
  5: '2B',
  6: '3S',
  7: '3B',
  8: '4S',
  9: '4B',
  10: '5S',
  11: 'END',
};

const scorePeriodsMap = {
  1: '1S',
  2: '2S',
  3: '3S',
  4: '4S',
  5: '5S',
};

function mapBeachVolleyPeriods(periods = []) {
  if (!periods?.length) return [];

  return periods.reduce((score, { id, value: n }) => {
    score.push({ label: scorePeriodsMap[id], scores: [n.home, n.away] });
    return score;
  }, []);
}

export function mapBeachVolleyEvent(event) {
  const currentScoreInCurrentSet = event.events?.find((event) => event.id === 2)?.value ?? {};

  return {
    displayEventStatus: true,
    eventStatus: statusMap[event.eventStatusId],
    eventStatusId: event.eventStatusId,
    periods: mapBeachVolleyPeriods(event.events?.find((event) => event.id === 3)?.periods),
    currentScoreInSets: event.events?.find((event) => event.id === 1)?.value ?? {},
    currentScoreInCurrentSet,
    currentGameScore: !isMobile
      ? (event.events?.find((event) => event.id === 1)?.value ?? {})
      : null,
    compactModeScores: [currentScoreInCurrentSet],
    server: event.events?.find((event) => event.id === 4)?.value ?? {},
  };
}
